import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';
import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';
import { sexEnum } from '@yonderlabs-packages/schemas-sex';

import {
  apiResponseFailureSchema,
  apiResponseSuccessSchema,
  paginatedApiResponseFailureSchema,
  paginatedApiResponseSuccessSchema,
} from '../base/apiResponse';
import { paginatedRequestQuerySchema } from '../base/paginatedQuery';

const residentialAddress = z.object({
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  city: z.string().optional(),
  countryCode: countryCodeEnum,
  postalCode: z.string().optional(),
});

export type ResidentialAddress = z.infer<typeof residentialAddress>;

export const deactivationReasonValidator = z.enum(
  ['Employee leaving the company', 'Employee wants to cancel their benefits', 'You want to cancel their benefits'],
  {
    errorMap: (issue, ctx) => ({ message: 'Please select a reason' }),
  },
);

const startDate = z.string();

const dateOfBirth = z.string();

const firstName = z.string().min(1, 'First name is required');

const lastName = z.string().min(1, 'Last name is required');

const annualSalary = z
  .number()
  .min(1, 'Annual salary is required')
  .transform((value) => parseInt(String(value), 10));

export const invitePersonSchema = z.object({
  annualSalary: annualSalary,
  dateOfBirth: dateOfBirth.optional(),
  email: z.string().email('Email is required'),
  employeeNumber: z.string().optional(),
  firstName,
  lastName,
  organizationContributionPlanId: z.string().uuid().optional(),
  residentialAddress: residentialAddress.optional(),
  role: z.string().optional(),
  salaryCurrency: currencyCodeEnum,
  sex: sexEnum.optional(),
  startDate: startDate.optional(),
  taxId: z.string().optional(),
});

export type InvitePerson = z.infer<typeof invitePersonSchema>;

const personInformationSchema = z.object({
  firstName,
  lastName,
  sex: sexEnum.optional(),
  dateOfBirth: dateOfBirth.optional(),
  taxId: z.string().optional(),
  residentialAddress: residentialAddress,
});

export type PersonInformation = z.infer<typeof personInformationSchema>;

export const personSchema = z.object({
  id: z.string().uuid(),
  employeeNumber: z.string().optional(),
  information: personInformationSchema,
  state: z.enum(['Active', 'Inactive']),
  workEmail: z.string().email('Email is required'),
  startDate: startDate,
  endDate: z.date().optional(),
  annualSalary: annualSalary,
  salaryCurrency: currencyCodeEnum,
  role: z.string().optional(),
  organizationContributionPlanId: z.string().uuid().optional(),
  hasBenefits: z.boolean(),
});

export type Person = z.infer<typeof personSchema>;

export const invitePersonErrorEnum = z.enum([
  'AgeDoesNotMeetRequirements',
  'AlreadyInvited',
  'ContributionPlanDoesNotExist',
  'EmployeeIdAlreadyInUse',
  'InvalidAnnualSalary',
  'InvalidGeoRegion',
  'InvalidStartDate',
  'InvalidTaxId',
  'RequiredFieldMissing',
  'TaxIdAlreadyInUse',
  'UnknownError',
]);

export const invitePersonSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const invitePersonErrorSchema = apiResponseFailureSchema.extend({
  errorCode: invitePersonErrorEnum,
});

export const inviteIncompletePersonErrorEnum = z.enum([
  'AgeDoesNotMeetRequirements',
  'AlreadyInvited',
  'ContributionPlanDoesNotExist',
  'EmployeeIdAlreadyInUse',
  'InvalidAnnualSalary',
  'InvalidGeoRegion',
  'InvalidIncompletePeopleId',
  'InvalidStartDate',
  'InvalidTaxId',
  'RequiredFieldMissing',
  'TaxIdAlreadyInUse',
  'UnknownError',
]);

export const inviteIncompletePersonErrorSchema = apiResponseFailureSchema.extend({
  errorCode: inviteIncompletePersonErrorEnum,
});

export const inviteIncompletePersonPathParamsSchema = z.object({
  personId: z.string().uuid(),
});

export const getPeopleQuerySchema = paginatedRequestQuerySchema.extend({
  countryCode: countryCodeEnum.optional(),
  hasPlan: z.boolean().optional(),
  hasBenefits: z.boolean().optional(),
});

export const getPeopleErrorEnum = z.enum(['OrganizationDoesNotExist', 'InvalidMinOrMaxSalaryFilter', 'UnknownError']);

export const getPeopleSuccessSchema = paginatedApiResponseSuccessSchema.extend({
  data: z.array(personSchema),
});

export const getPeopleErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getPeopleErrorEnum,
});

export const getIncompletePeopleCountByCountryCodePathSchema = z.object({
  countryCode: countryCodeEnum,
});

const incompletePeopleCount = z.object({
  count: z.number().nonnegative(),
});

export const getIncompletePeopleCountByCountryCodeSuccessSchema = apiResponseSuccessSchema.extend({
  data: incompletePeopleCount,
});

export const getIncompletePeopleCountByCountryCodeErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getPeopleErrorEnum,
});

export const getPersonPathSchema = z.object({
  personId: z.string().uuid(),
});

export const getPersonQuerySchema = z.object({
  includeUserInformation: z.boolean().optional(),
});

export const getPersonErrorEnum = z.enum(['UserDoesNotExist', 'UnknownError']);

export const getPersonSuccessSchema = paginatedApiResponseSuccessSchema.extend({
  data: personSchema,
});

export const getPersonErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getPeopleErrorEnum,
});

export const updatePersonSchema = z.object({
  id: z.string().uuid(),
  employeeNumber: z.string().optional(),
  role: z.string().optional(),
  startDate: startDate.optional(),
  endDate: z.date().optional(),
  annualSalary: annualSalary,
  residentialAddress: residentialAddress
    .pick({
      addressLine1: true,
    })
    .optional(),
  salaryCurrency: currencyCodeEnum,
  firstName: firstName.optional(),
  lastName: lastName.optional(),
  taxId: z.string().optional(),
  sex: sexEnum.optional(),
  dateOfBirth: dateOfBirth.optional(),
});

export const updatePersonSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const updatePersonErrorEnum = z.enum([
  'UserAgeDoesNotMeetRequirements',
  'UserDoesNotExist',
  'UnknownError',
  'EmployeeIdAlreadyInUse',
  'InvalidTaxId',
  'TaxIdAlreadyInUse',
]);

export const updatePersonErrorSchema = apiResponseFailureSchema.extend({
  errorCode: updatePersonErrorEnum,
});

export const deletePersonPathSchema = z.object({
  personId: z.string().uuid(),
});

export const deletePersonErrorEnum = z.enum([
  'UserDoesNotExist',
  'UnknownError',
  'UnableToDeactiveUserConnectedToHris',
]);

export const deletePersonSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const deletePersonErrorSchema = apiResponseFailureSchema.extend({
  errorCode: deletePersonErrorEnum,
});

export const deletePersonBodySchema = z
  .object({
    deactivationReason: deactivationReasonValidator,
    deactivationDate: z.date(),
  })
  .or(z.object({}));

export const conflictingPersonSchema = personSchema
  .extend({
    workEmail: z.string().email().optional(),
  })
  .pick({
    id: true,
    workEmail: true,
    employeeNumber: true,
  })
  .merge(
    personInformationSchema
      .extend({
        firstName: z.string().optional(),
        lastName: z.string().optional(),
      })
      .pick({
        firstName: true,
        lastName: true,
        taxId: true,
      }),
  );

export type ConflictingPerson = z.infer<typeof conflictingPersonSchema>;

export const getConflictingPeopleSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(conflictingPersonSchema),
});

export const getConflictingPeopleErrorEnum = z.enum(['UnknownError']);
export const getConflictingPeopleErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getConflictingPeopleErrorEnum,
});

export const conflictingPersonWithMatchesSchema = z.object({
  person: conflictingPersonSchema,
  matches: z.array(conflictingPersonSchema),
});

export type ConflictingPersonWithMatches = z.infer<typeof conflictingPersonWithMatchesSchema>;

export const getConflictingPersonPathSchema = z.object({
  peopleId: z.string().uuid(),
});

export const getConflictingPersonSuccessSchema = apiResponseSuccessSchema.extend({
  data: conflictingPersonWithMatchesSchema,
});

export const getConflictingPersonErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getConflictingPeopleErrorEnum,
});

export const resolveConflictSchema = z.object({
  sourcePersonId: z.string().uuid(),
  targetPersonId: z.string().uuid(),
});

export const resolveConflictSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const resolveConflictErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getConflictingPeopleErrorEnum,
});

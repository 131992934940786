import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';

import {
  apiResponseFailureSchema,
  apiResponseSuccessSchema,
  paginatedApiResponseFailureSchema,
  paginatedApiResponseSuccessSchema,
} from '../base/apiResponse';

const vatSchema = z.object({
  countryCode: countryCodeEnum,
  rate: z.number().min(0),
});

const getVatRatesErrors = z.enum(['UnknownError']);

export const getVatRatesSuccessSchema = paginatedApiResponseSuccessSchema.extend({
  data: z.array(vatSchema),
});

export const getVatRatesErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getVatRatesErrors,
});

export const getVatRatePathParamsSchema = z.object({
  countryCode: countryCodeEnum,
});

const getVatRateErrors = getVatRatesErrors;

export const getVatRateSuccessSchema = apiResponseSuccessSchema.extend({
  data: vatSchema,
});

export const getVatRateErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getVatRateErrors,
});

import isIP from 'validator/lib/isIP';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { z } from 'zod';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const password = z.string({ required_error: 'Password is required' }).refine(
  (password) =>
    isStrongPassword(password, {
      minLength: 8,
      minNumbers: 1,
      minSymbols: 1,
      minUppercase: 1,
      returnScore: false,
    }),
  {
    message:
      'Password must be at least 8 characters long and contain at least one uppercase character, one number, and one special character',
  },
);

const authResponse = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  expires_in: z.number().min(0),
});

export const registerAccountSchema = z.object({
  firstName: z.string().optional(),
  familyName: z.string().optional(),
  email: z.string({ required_error: 'Email is required' }).email({ message: 'Email is not valid' }),
  password,
  phoneNumber: z
    .string()
    .refine((phoneNumber) => isMobilePhone(phoneNumber), {
      message: 'Phone number is not valid',
    })
    .optional(),
  ipAddress: z
    .string()
    .refine((ip) => isIP(ip), {
      message: 'IP Address is not valid',
    })
    .optional(),
  userAgent: z.string().optional(),
  deviceMake: z.string().optional(),
  deviceModel: z.string().optional(),
  userType: z.enum(['Contractor', 'Organization']).default('Organization'),
});

export const registerAccountErrorEnum = z.enum([
  'DuplicateEmail',
  'InvalidClientCredentials',
  'InvalidFirstNameOrLastName',
  'PasswordRequiresDigit',
  'PasswordRequiresLower',
  'PasswordRequiresNonAlphanumeric',
  'PasswordRequiresUpper',
  'PasswordTooShort',
  'UnknownError',
]);

export const registerAccountSuccessSchema = z.object({
  success: z.boolean(),
});

export const registerAccountErrorSchema = apiResponseFailureSchema.extend({
  errorCode: registerAccountErrorEnum,
});

export const refreshTokenSchema = z.object({
  refreshToken: z.string(),
});

export const refreshTokenErrorEnum = z.enum(['InvalidClientCredentials', 'UnknownError', 'InvalidRefreshToken']);

export const refreshTokenSuccessSchema = apiResponseSuccessSchema.extend({
  data: authResponse,
});

export const refreshTokenErrorSchema = apiResponseFailureSchema.extend({
  errorCode: refreshTokenErrorEnum,
});

export const loginSchema = z.object({
  email: z.string().email(),
  password,
  mfaCode: z.string().optional(),
});

export const loginErrorEnum = z.enum([
  'EmailVerificationRequired',
  'InvalidCredentials',
  'InvalidClientCredentials',
  'MfaInvalid',
  'MfaRequired',
  'PhoneNumberVerificationRequired',
  'UnknownError',
  'UserDoesntExist',
  'UserLockedOut',
]);
export const loginSuccessSchema = apiResponseSuccessSchema.extend({
  data: authResponse,
});

export const loginErrorSchema = apiResponseFailureSchema.extend({
  errorCode: loginErrorEnum,
});

export const confirmEmailSchema = z.object({
  email: z.string().email(),
  code: z.string(),
});

export const confirmEmailErrorEnum = z.enum([
  'UserDoesntExist',
  'UnknownError',
  'EmailConfirmedAlready',
  'InvalidConfirmationCode',
  'InvalidClientCredentials',
]);

export const confirmEmailSuccessSchema = apiResponseSuccessSchema.extend({
  data: authResponse,
});

export const confirmEmailErrorSchema = apiResponseFailureSchema.extend({
  errorCode: confirmEmailErrorEnum,
});

export const resendEmailConfirmationSchema = z.object({
  email: z.string().email(),
});

export const resendEmailConfirmationErrorEnum = z.enum([
  'UserDoesNotExist',
  'UserEmailAlreadyVerified',
  'InvalidClientCredentials',
]);

export const resendEmailConfirmationSuccessSchema = apiResponseSuccessSchema.extend({
  success: z.boolean(),
});

export const resendEmailConfirmationErrorSchema = apiResponseFailureSchema.extend({
  errorCode: resendEmailConfirmationErrorEnum,
});

export const resetPasswordSchema = z.object({
  email: z.string().email(),
  code: z.string(),
  password,
});

export const resetPasswordErrorEnum = z.enum([
  'UserDoesntExist',
  'InvalidClientCredentials',
  'InvalidConfirmationCode',
]);

export const resetPasswordSuccessSchema = apiResponseSuccessSchema.extend({
  success: z.boolean(),
});

export const resetPasswordErrorSchema = apiResponseFailureSchema.extend({
  errorCode: resetPasswordErrorEnum,
});

export const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

export const forgotPasswordErrorEnum = z.enum(['UserDoesNotExistOrEmailNotConfirmed', 'InvalidClientCredentials']);

export const forgotPasswordSuccessSchema = apiResponseSuccessSchema.extend({
  success: z.boolean(),
});

export const forgotPasswordErrorSchema = apiResponseFailureSchema.extend({
  errorCode: forgotPasswordErrorEnum,
});

import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const organizationSignatoryStatusEnum = z.enum(['Started', 'Sent', 'Signed']);

export type OrganizationSignatoryStatus = z.infer<typeof organizationSignatoryStatusEnum>;

const baseSignatorySchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  email: z.string().email(),
});

const organizationSignatoriesSchema = baseSignatorySchema.extend({
  signatureStatus: organizationSignatoryStatusEnum,
  countries: z.array(countryCodeEnum),
});

export type OrganizationSignatory = z.infer<typeof organizationSignatoriesSchema>;

export const getOrganizationSignatoriesSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(organizationSignatoriesSchema),
});

export const signatoriesErrorsEnum = z.enum(['UnknownError', 'UnknownOrganization']);

export const getOrganizationSignatoriesErrorSchema = apiResponseFailureSchema.extend({
  errorCode: signatoriesErrorsEnum,
});

export const createOrganizationSignatorySchema = baseSignatorySchema.extend({
  countries: z.array(countryCodeEnum),
});

export const createOrganizationSignatorySuccessSchema = apiResponseSuccessSchema.extend({
  data: organizationSignatoriesSchema,
});

export const createOrganizationSignatoryErrorSchema = apiResponseFailureSchema.extend({
  errorCode: signatoriesErrorsEnum,
});

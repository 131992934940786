import { z } from 'zod';

import {
  countryCodeEnum,
  incorporatedCountryCodeEnum,
  lowerCaseCountryCodeEnum,
  type LowerCaseCountryCode,
} from '@yonderlabs-packages/schemas-country';
import {
  organizationSizeEnum,
  typeOfCompanyEnum,
  typeOfContractorEnum,
} from '@yonderlabs-packages/schemas-organization';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';
import { paginatedRequestQuerySchema } from '../base/paginatedQuery';

export const createOrganizationSchema = z.object({
  legalName: z.string({ required_error: 'Name is required' }).min(1, 'Name is required'),
  alpha2CountryCode: z.string({ required_error: 'Please select a country' }).refine(
    (val) => {
      const parsedCountryCode = incorporatedCountryCodeEnum.safeParse(val);
      if (parsedCountryCode.success) {
        return 1;
      }

      return 0;
    },
    {
      message: 'Please select a country from the list provided',
    },
  ),
  typeOfCompany: typeOfCompanyEnum.or(typeOfContractorEnum),
  size: organizationSizeEnum.default('1-10'),
  registrationNumber: z.string().optional(),
  vatId: z.string().optional(),
});

export const createOrganizationErrorEnum = z.enum([
  'InvalidUser',
  'OrganizationAlreadyExists',
  'InvalidOrganizationName',
  'InvalidOrganizationSize',
  'InvalidOrganizationCountryCode',
  'InvalidOrganizationType',
  'NotPartOfTheBeta',
  'UnknownError',
]);

export const createOrganizationSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const createOrganizationErrorSchema = apiResponseFailureSchema.extend({
  errorCode: createOrganizationErrorEnum,
});

export const addOrganizationRegistrationNumberErrorEnum = z.enum(['InvalidOrganization', 'UnknownError']);

export const addOrganizationRegistrationNumberPathSchema = z.object({
  countryCode: countryCodeEnum,
});

export const addOrganizationRegistrationNumberSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const addOrganizationRegistrationNumberErrorSchema = apiResponseFailureSchema.extend({
  errorCode: addOrganizationRegistrationNumberErrorEnum,
});

export const addOrganizationStagingDateErrorEnum = z.enum(['InvalidOrganization', 'UnknownError']);

export const addOrganizationStagingDatePathSchema = z.object({
  countryCode: countryCodeEnum,
});

export const addOrganizationStagingDateSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const addOrganizationStagingDateErrorSchema = apiResponseFailureSchema.extend({
  errorCode: addOrganizationRegistrationNumberErrorEnum,
});

export const checkBetaInviteQueryParamsSchema = z.object({
  email: z.string().email(),
});

export const emailQueryParamsSchema = z.string().email();

export const checkBetaInviteStatusErrorEnum = z.enum(['UnknownError', 'NotPartOfTheBeta']);

export const checkBetaInviteStatusSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.undefined(),
});

export const checkBetaInviteStatusErrorSchema = z.object({
  errorCode: checkBetaInviteStatusErrorEnum,
});

export const getOrganizationErrorEnum = z.enum(['UnknownError', 'InvalidOrganization']);

export const smartMetadataSchema = z.object({
  companyId: z.number(),
  schemaId: z.number(),
  slug: z.string(),
});

export const gbPensionMetadataSchema = z.object({
  smart: smartMetadataSchema.optional(),
});

export const defaultPensionMetadataSchema = z.undefined();

export const gbCountryMetadataSchema = z.object({
  registrationNumber: z.string().optional(),
  hasAcknowledgedDeclarationOfConsent: z.boolean().optional(),
  stagingDate: z
    .string()
    .transform((value) => new Date(value))
    .optional(),
});

export const defaultCountryMetadataSchema = z.object({
  registrationNumber: z.string().optional(),
});

export const discriminatedOrganizationMetadataSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('GB'),
    country: gbCountryMetadataSchema.optional(),
    pension: gbPensionMetadataSchema.optional(),
  }),
  z.object({
    type: z.literal('EU'),
    country: defaultCountryMetadataSchema.optional(),
    pension: defaultPensionMetadataSchema,
  }),
]);

export const countryMetadataSchema = z.discriminatedUnion('type', [
  gbCountryMetadataSchema.extend({
    type: z.literal('GB'),
  }),
  defaultCountryMetadataSchema.extend({
    type: z.literal('EU'),
  }),
]);

export type GbCountryMetadata = z.infer<typeof gbCountryMetadataSchema>;
export type DefaultCountryMetadata = z.infer<typeof defaultCountryMetadataSchema>;
type CountryMetadata = {
  [key in LowerCaseCountryCode]?: GbCountryMetadata | DefaultCountryMetadata;
};

const countrySchema: z.ZodType<CountryMetadata, z.ZodTypeDef> = z
  .any()
  .refine((value) => {
    if (typeof value !== 'object') {
      return false;
    }

    for (const countryCode in value) {
      if (!lowerCaseCountryCodeEnum.options.includes(countryCode as any)) {
        return false;
      }

      const countryData = value[countryCode];

      if (countryCode === lowerCaseCountryCodeEnum.enum.gb) {
        if (!gbCountryMetadataSchema.safeParse(countryData).success) {
          return false;
        }
      } else if (!defaultCountryMetadataSchema.safeParse(countryData).success) {
        return false;
      }
    }

    return true;
  })
  .transform((value) => {
    return Object.fromEntries(Object.entries(value).map(([key, val]) => [key.toUpperCase(), val]));
  });

const organizationMetadataSchema = z
  .object({
    country: countrySchema.optional().default({}),
    pension: defaultPensionMetadataSchema.or(gbPensionMetadataSchema).optional(),
  })
  .default({});

export type OrganizationMetadata = z.infer<typeof organizationMetadataSchema>;

const organizationSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  incorporatedCountry: incorporatedCountryCodeEnum,
  type: typeOfCompanyEnum.or(typeOfContractorEnum),
  vatId: z.string().optional(),
  metadata: organizationMetadataSchema,
});

export type Organization = z.infer<typeof organizationSchema>;

export const getOrganizationSuccessSchema = apiResponseSuccessSchema.extend({
  data: organizationSchema,
});

export const getOrganizationErrorSchema = z.object({
  errorCode: getOrganizationErrorEnum,
});

export const getOrganizationAdminsSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(
    z.object({
      id: z.string().uuid(),
      userName: z.string(),
      email: z.string().email(),
      firstName: z.string(),
      lastName: z.string(),
      phoneNumber: z.string(),
      emailConfirmed: z.boolean(),
      phoneNumberConfirmed: z.boolean(),
      twoFactorEnabled: z.boolean(),
      roles: z.array(z.string()),
    }),
  ),
});

export const getOrganizationAdminsErrorSchema = apiResponseFailureSchema;

export const getAdminsQueryParamsSchema = paginatedRequestQuerySchema.extend({
  searchOrgId: z.string().uuid(),
});

export const adminRolesEnum = z.enum(['OrganizationOwner', 'OrganizationAdmin', 'OrganizationManager']);

export const addAdminSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  role: adminRolesEnum,
});

export const addAdminSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const addAdminErrorSchema = apiResponseFailureSchema.extend({
  errorCode: z.enum(['Invalid User']),
});

export const removeAdminSchema = z.object({
  userId: z.string().uuid(),
});

export const removeAdminSuccessSchema = apiResponseSuccessSchema;
export const removeAdminErrorSchema = removeAdminSuccessSchema;

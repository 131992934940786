import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';
import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';

import {
  apiResponseFailureSchema,
  apiResponseFailureSchemaWithErrorArray,
  apiResponseSuccessSchema,
} from '../base/apiResponse';

const moneySchema = z.object({
  amount: z.number(),
  currency: currencyCodeEnum,
});

export const country = z.object({
  countryCode: countryCodeEnum,
  hasHealth: z.boolean(),
  hasPension: z.boolean(),
});

export const getBenefitsCountriesSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.object({
    activeCountries: z.array(country),
    availableCountries: z.array(country),
  }),
});

export const getBenefitsCountriesErrorSchema = apiResponseFailureSchema.extend({
  errorCode: z.enum(['UnknownError']),
});

export const groupHealthBenefitPlanOptionSchema = z.object({
  planOptionId: z.string().uuid(),
  planSubOptionId: z.string({ required_error: 'Please select an option' }).uuid(),
});

export type GroupHealthBenefitPlanOption = z.infer<typeof groupHealthBenefitPlanOptionSchema>;

export const groupHealthBenefitPlanOptionsSchema = z.array(groupHealthBenefitPlanOptionSchema);

export const createGroupHealthBenefitSchema = z.object({
  planId: z.string().uuid(),
  options: groupHealthBenefitPlanOptionsSchema,
  hadPreviousInsurance: z.boolean(),
});

export const createGroupHealthBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const createGroupHealthBenefitErrorEnum = z.enum([
  'None',
  'UnhandledError',
  'InvalidPlan',
  'InvalidOption',
  'InvalidSubOption',
  'MissingSubOption',
  'InvalidAddon',
  'RequiredOptionMissing',
  'BenefitLimitReached',
  'UnknownError',
]);

export type CreateGroupHealthBenefitError = z.infer<typeof createGroupHealthBenefitErrorEnum>;

export const createGroupHealthBenefitErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: createGroupHealthBenefitErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const getGroupHealthBenefitsQuerySchema = z.object({
  countryCode: countryCodeEnum,
});

const groupHealthStateEnum = z.enum(['Draft', 'Processing', 'Pending', 'Accepted', 'Completed']);

export type GroupHealthState = z.infer<typeof groupHealthStateEnum>;

const groupHealthBenefitsSchema = z.object({
  id: z.string().uuid(),
  planId: z.string().uuid(),
  state: groupHealthStateEnum,
  totalMembers: z.number(),
  currentCost: z.object({
    amount: z.number(),
    currency: currencyCodeEnum,
  }),
});

const groupHealthBenefitCoverStateEnum = z.enum(['Active', 'Cancelled']);
const groupHealthBenefitCoverCancelledReasonEnum = z.enum(['None', 'Employee', 'Provider', 'Revised']);

const groupHealthBenefitCoverSchema = z.object({
  id: z.string().uuid(),
  state: groupHealthBenefitCoverStateEnum,
  cancelledReason: groupHealthBenefitCoverCancelledReasonEnum.optional(),
  policyNumber: z.string(),
  activeDate: z.date(),
  renewalDate: z.date(),
  monthlyPremium: moneySchema,
});

export const groupHealthBenefitQuoteStateEnum = z.enum([
  'Created',
  'Processing',
  'Pending',
  'Accepted',
  'AcceptedProcessing',
  'Rejected',
  'Completed',
]);

export type GroupHealthBenefitQuoteState = z.infer<typeof groupHealthBenefitQuoteStateEnum>;

const groupHealthBenefitQuoteRejectedStateEnum = z.enum(['None', 'Employer', 'Provider']);

const groupHealthBenefitQuoteSchema = z.object({
  state: groupHealthBenefitQuoteStateEnum,
  rejectionReason: groupHealthBenefitQuoteRejectedStateEnum.optional(),
  monthlyPremium: z.number().optional(),
});

const groupHealthBenefitMemberSchema = z.object({
  id: z.string().uuid(),
  workProfileId: z.string().uuid(),
});

const groupHealthProviderEnum = z.enum(['Vitality']);

const groupHealthBenefitSchema = z.object({
  id: z.string().uuid(),
  planId: z.string().uuid(),
  state: groupHealthStateEnum,
  countryCode: countryCodeEnum,
  provider: groupHealthProviderEnum,
  hadPreviousInsurance: z.boolean().optional(),
  members: z.array(groupHealthBenefitMemberSchema),
  quote: groupHealthBenefitQuoteSchema.optional(),
  cover: groupHealthBenefitCoverSchema.optional(),
  selectedOptions: z.array(groupHealthBenefitPlanOptionSchema),
});

export const getGroupHealthBenefitsSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(groupHealthBenefitsSchema),
});

export const getGroupHealthBenefitsErrorEnum = z.enum(['None', 'UnknownError']);

export type GetGroupHealthBenefitsError = z.infer<typeof getGroupHealthBenefitsErrorEnum>;

export const getGroupHealthBenefitsErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthBenefitsErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const getGroupHealthBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: groupHealthBenefitSchema,
});

export const getGroupHealthBenefitErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthBenefitsErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const groupHealthBenefitPathSchema = z.object({
  benefitId: z.string().uuid(),
});

export const groupLifeBenefitPathSchema = z.object({
  benefitId: z.string().uuid(),
});

export const updateGroupHealthBenefitSchema = createGroupHealthBenefitSchema;

export const updateGroupHealthBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.null(),
});

export const updateGroupHealthBenefitsErrorEnum = z.enum([
  'None',
  'UnhandledError',
  'BenefitNotFound',
  'BenefitNoLongerDraft',
  'InvalidPlan',
  'InvalidOption',
  'InvalidSubOption',
  'MissingSubOption',
  'InvalidAddon',
  'RequiredOptionMissing',
  'UnknownError',
]);

export type UpdateGroupHealthBenefitsError = z.infer<typeof updateGroupHealthBenefitsErrorEnum>;

export const updateGroupHealthBenefitsErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: updateGroupHealthBenefitsErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const getGroupHealthBillingInformationSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const getGroupHealthBillingInformationErrorEnum = z.enum([
  'None',
  'UnknownBenefit',
  'NoBillingInformationAssociated',
  'UnknownError',
]);

export type GetGroupHealthBillingInformationError = z.infer<typeof getGroupHealthBillingInformationErrorEnum>;

export const getGroupHealthBillingInformationErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthBillingInformationErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const updateGroupHealthBenefitBillingInformationSchema = z.object({
  billingInformationId: z.string().uuid(),
});

export const updateGroupHealthBillingInformationSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const updateGroupHealthBillingInformationErrorEnum = z.enum([
  'None',
  'UnknownBenefit',
  'UnknownBillingInformationId',
  'MissingProviderImplementation',
  'RefusedByProvider',
  'UnknownError',
]);

export type UpdateGroupHealthBillingInformationError = z.infer<typeof updateGroupHealthBillingInformationErrorEnum>;

export const updateGroupHealthBillingInformationErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: updateGroupHealthBillingInformationErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const addMemberToGroupHealthBenefitSchema = z.object({
  workProfileId: z.string().uuid(),
});

export const addMemberToGroupHealthBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.null(),
});

export const addMemberToGroupHealthBenefitErrorEnum = z.enum([
  'None',
  'UnknownBenefit',
  'PersonNotFound',
  'AlreadyInBenefit',
  'UnsuitableBenefitState',
  'UnknownError',
]);

export type AddMemberToGroupHealthBenefitError = z.infer<typeof addMemberToGroupHealthBenefitErrorEnum>;

export const addMemberToGroupHealthBenefitsErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: addMemberToGroupHealthBenefitErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const addMembersToGroupHealthBenefitSchema = z.object({
  workProfileIds: z.array(z.string().uuid()),
});

export const addMembersToGroupHealthQuerySchema = z
  .object({
    overrideMembers: z.boolean().optional(),
  })
  .optional();

export const addMembersToGroupHealthBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(z.string().uuid()),
});

export const addMembersToGroupHealthBenefitsErrorEnum = z.enum([
  'None',
  'Unexpected',
  'UnknownBenefit',
  'NoLongerDraftBenefit',
  'UnsuitableBenefitState',
  'ContainsInvalidPerson',
  'UnknownError',
]);

export type AddMembersToGroupHealthBenefitError = z.infer<typeof addMembersToGroupHealthBenefitsErrorEnum>;

export const addMembersToGroupHealthBenefitsErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: addMembersToGroupHealthBenefitsErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const getGroupHealthBenefitEligibleMembersQuerySchema = z
  .object({
    includeNotAttached: z.boolean().optional(),
  })
  .optional();

const eligibleMemberCoverStateEnum = z.enum(['Active', 'Processing', 'None', 'Terminated']);

export type EligibleMemberCoverState = z.infer<typeof eligibleMemberCoverStateEnum>;

export const eligibleMemberSchema = z.object({
  id: z.string().uuid().or(z.null()),
  benefitId: z.string().uuid().or(z.null()),
  workProfileId: z.string().uuid(),
  userProfileId: z.string().uuid(),
  email: z.string().email(),
  coverState: eligibleMemberCoverStateEnum,
  firstName: z.string(),
  lastName: z.string(),
  salary: moneySchema,
});

export type EligibleMember = z.infer<typeof eligibleMemberSchema>;

export const getGroupHealthBenefitEligibleMembersSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(eligibleMemberSchema),
});

export const getGroupHealthBenefitEligibleMembersErrorEnum = z.enum(['None', 'UnknownBenefit', 'UnknownError']);

export type GetGroupHealthBenefitEligibleMembersError = z.infer<typeof getGroupHealthBenefitEligibleMembersErrorEnum>;

export const getGroupHealthBenefitEligibleMembersErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthBenefitEligibleMembersErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const groupHealthBenefitIndustrySchema = z.object({
  industry: z.string(),
});

export const getGroupHealthBenefitIndustrySuccessSchema = apiResponseSuccessSchema.extend({
  data: groupHealthBenefitIndustrySchema,
});

export const getGroupHealthBenefitIndustryErrorEnum = z.enum(['None', 'UnknownBenefit', 'UnknownError']);

export type GetGroupHealthBenefitIndustryError = z.infer<typeof getGroupHealthBenefitIndustryErrorEnum>;

export const getGroupHealthBenefitIndustryErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthBenefitIndustryErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const setGroupHealthBenefitIndustrySuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const setGroupHealthBenefitIndustryErrorEnum = z.enum([
  'None',
  'UnknownBenefit',
  'BenefitNoLongerDraft',
  'ProviderError',
  'UnknownError',
]);

export type SetGroupHealthBenefitIndustryError = z.infer<typeof setGroupHealthBenefitIndustryErrorEnum>;

export const setGroupHealthBenefitIndustryErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: setGroupHealthBenefitIndustryErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const getGroupHealthPlansQuerySchema = z.object({
  countryCode: countryCodeEnum,
});

const groupHealthPlanTableOfCoverSubSectionSchema = z.object({
  benefit: z.string(),
  coverage: z.string(),
});

export type GroupHealthPlanTableOfCoverSubSection = z.infer<typeof groupHealthPlanTableOfCoverSubSectionSchema>;

const groupHealthPlanTableOfCoverSectionSchema = z.object({
  name: z.string(),
  subSections: z.array(groupHealthPlanTableOfCoverSubSectionSchema),
});

export type GroupHealthPlanTableOfCoverSection = z.infer<typeof groupHealthPlanTableOfCoverSectionSchema>;

const groupHealthPlanTableOfCoverItemSchema = z.object({
  name: z.string(),
  description: z.string(),
  icon: z.string(),
  sections: z.array(groupHealthPlanTableOfCoverSectionSchema),
});

export type GroupHealthPlanTableOfCover = z.infer<typeof groupHealthPlanTableOfCoverItemSchema>;

const groupHealthPlanOptionSubOptionSchema = z.object({
  id: z.string().uuid(),
  value: z.string(),
  tableOfCover: z.array(groupHealthPlanTableOfCoverItemSchema).optional(),
});

export type GroupHealthPlanOptionSubOption = z.infer<typeof groupHealthPlanOptionSubOptionSchema>;

export const groupHealthPlanOptionTitleEnum = z.enum([
  'EmergencyOverseasCover',
  'EmployeeAssistanceProgram',
  'ExcessAmount',
  'ExcessType',
  'HospitalOption',
  'MentalHealthCover',
  'OpticalDentalHearingCover',
  'OutpatientDiagnostics',
  'OutpatientCover',
  'TherapiesCover',
  'WorldwideTravelCover',
]);

export type GroupHealthPlanOptionTitle = z.infer<typeof groupHealthPlanOptionTitleEnum>;

export const groupHealthPlanRequiredAddonsEnum = groupHealthPlanOptionTitleEnum.exclude([
  'EmergencyOverseasCover',
  'EmployeeAssistanceProgram',
  'MentalHealthCover',
  'OpticalDentalHearingCover',
  'TherapiesCover',
  'WorldwideTravelCover',
]);

export const groupHealthPlanOptionalAddonsEnum = groupHealthPlanOptionTitleEnum.exclude([
  'ExcessAmount',
  'ExcessType',
  'HospitalOption',
  'OutpatientCover',
]);

const groupHealthPlanOptionSchema = z.object({
  id: z.string().uuid(),
  title: groupHealthPlanOptionTitleEnum,
  description: z.string(),
  required: z.boolean(),
  subOptions: z.array(groupHealthPlanOptionSubOptionSchema),
});

export type GroupHealthPlanOption = z.infer<typeof groupHealthPlanOptionSchema>;

export const groupHealthPlanDocumentTypeEnum = z.enum([
  'BenefitLocations',
  'Help',
  'MakeAClaim',
  'InsuranceProductInformationDocument',
]);

export type GroupHealthPlanDocumentType = z.infer<typeof groupHealthPlanDocumentTypeEnum>;

const groupHealthPlanDocumentSchema = z.object({
  type: groupHealthPlanDocumentTypeEnum,
  uri: z.string().url(),
  activeFrom: z.date(),
});

export type GroupHealthPlanDocument = z.infer<typeof groupHealthPlanDocumentSchema>;

export const groupHealthPlanSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  provider: z.string(),
  providerName: z.string(),
  iconUri: z.string().url(),
  tableOfCover: z.array(groupHealthPlanTableOfCoverItemSchema),
  options: z.array(groupHealthPlanOptionSchema),
  uris: z.array(groupHealthPlanDocumentSchema),
});

export const getGroupHealthPlansSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(groupHealthPlanSchema),
});

export const getGroupHealthPlansErrorEnum = z.enum(['None', 'UnknownError']);

export type GetGroupHealthPlansError = z.infer<typeof getGroupHealthPlansErrorEnum>;

export const getGroupHealthPlansErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthPlansErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const getGroupHealthPlanPathSchema = z.object({
  planId: z.string().uuid(),
});

export const getGroupHealthPlanSuccessSchema = apiResponseSuccessSchema.extend({
  data: groupHealthPlanSchema,
});

export const getGroupHealthPlanErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthPlansErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const requestGroupHealthBenefitQuoteBodySchema = z.never();

export const requestGroupHealthBenefitQuoteSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.string().uuid(),
});

export const requestGroupHealthBenefitQuoteErrorEnum = z.enum([
  'None',
  'ProviderError',
  'InvalidMemberCount',
  'InvalidPlan',
  'MissingBillingInformation',
  'MissingPreviousInsuranceInformation',
  'InternalErrorOccurred',
  'InvalidPlanOptionCount',
  'UnknownError',
]);

export type RequestGroupHealthBenefitQuoteError = z.infer<typeof requestGroupHealthBenefitQuoteErrorEnum>;

export const requestGroupHealthBenefitQuoteErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: requestGroupHealthBenefitQuoteErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const activeQuoteSchema = z.object({
  quoteId: z.string().uuid(),
  provider: groupHealthProviderEnum,
  state: groupHealthBenefitQuoteStateEnum,
  rejectionReason: groupHealthBenefitQuoteRejectedStateEnum,
  validUntil: z.date(),
  startDate: z.date(),
  monthlyPremium: z.number(),
  annualPremium: z.number(),
  totalIncludingSaasFee: z.number(),
  saasFeePerMember: z.number(),
  totalMembers: z.number(),
  currencyCode: currencyCodeEnum,
});

export const getGroupHealthBenefitQuoteSuccessSchema = apiResponseSuccessSchema.extend({
  data: activeQuoteSchema,
});

export const getGroupHealthBenefitQuoteErrorEnum = z.enum(['None', 'InvalidBenefit', 'UnknownError']);

export type GetGroupHealthBenefitQuoteError = z.infer<typeof getGroupHealthBenefitQuoteErrorEnum>;

export const getGroupHealthBenefitQuoteErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getGroupHealthBenefitQuoteErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const approveGroupHealthBenefitQuoteSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const approveGroupHealthBenefitQuoteErrorEnum = z.enum([
  'None',
  'InvalidBenefit',
  'InvalidQuoteState',
  'MissingPaymentMethod',
  'UnknownError',
]);

export type ApproveGroupHealthBenefitQuoteError = z.infer<typeof approveGroupHealthBenefitQuoteErrorEnum>;

export const approveGroupHealthBenefitQuoteErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: approveGroupHealthBenefitQuoteErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const declineGroupHealthBenefitQuoteSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const declineGroupHealthBenefitQuoteErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: z.string(),
      cause: z.string().nullable(),
    }),
  ),
});

export const progressDemoGroupHealthBenefitBodySchema = z.never();

export const progressDemoGroupHealthBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const groupLifeDemoBenefitErrorResponseEnum = z.enum([
  'None',
  'UnknownQuote',
  'FailedToObtainData',
  'UnknownError',
]);

export type GroupLifeDemoBenefitErrorResponse = z.infer<typeof groupLifeDemoBenefitErrorResponseEnum>;

export const progressDemoGroupHealthBenefitErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: groupLifeDemoBenefitErrorResponseEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const deleteDemoGroupHealthBenefitErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: groupLifeDemoBenefitErrorResponseEnum,
      cause: z.string().nullable(),
    }),
  ),
});

export const progressDemoGroupLifeBenefitBodySchema = z.never();

export const progressDemoGroupLifeBenefitSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const progressDemoGroupLifeBenefitErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: z.string(),
      cause: z.string().nullable(),
    }),
  ),
});

import { z } from 'zod';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

export const factoryResetSchema = z.object({
  deleteBillingInformation: z.boolean().optional(),
  deletePackages: z.boolean().optional(),
  deleteEmployees: z.boolean().optional(),
});

export const createFactoryResetSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

export const createFactoryResetErrorSchema = apiResponseFailureSchema.extend({
  errorCode: z.null(),
});

import { z } from 'zod';

const apiResponseSchema = z.object({
  success: z.boolean(),
  data: z.string().or(z.object({})).nullable(),
  errorCode: z.string().nullable(),
  errorCause: z.string().nullable(),
});

const apiResponseSchemaWithErrorArray = z.object({
  success: z.boolean(),
  data: z.string().or(z.object({})).nullable(),
  errors: z
    .array(
      z.object({
        errorCode: z.string().nullable(),
        errorCause: z.string().nullable(),
      }),
    )
    .nullable(),
});

export const apiResponseSuccessSchema = apiResponseSchema.extend({
  success: z.literal(true),
  errorCode: z.null(),
  errorCause: z.null(),
});

export const apiResponseFailureSchema = apiResponseSchema.extend({
  success: z.literal(false),
  data: z.null(),
});

export const apiResponseFailureSchemaWithErrorArray = apiResponseSchemaWithErrorArray.extend({
  success: z.literal(false),
  data: z.null(),
});

const paginatedApiResponseSchema = apiResponseSchema.extend({
  currentPage: z.number().min(0).optional(),
  totalPages: z.number().min(0).optional(),
  pageSize: z.number().min(0).optional(),
  totalCount: z.number().min(0).optional(),
});

export const paginatedApiResponseSuccessSchema = paginatedApiResponseSchema.extend({
  success: z.literal(true),
  errorCode: z.null(),
  errorCause: z.null(),
  currentPage: z.number().min(0),
  totalPages: z.number().min(0),
  pageSize: z.number().min(0),
  totalCount: z.number().min(0),
});

export const paginatedApiResponseFailureSchema = paginatedApiResponseSchema.extend({
  success: z.literal(false),
  currentPage: z.null(),
  totalPages: z.null(),
  pageSize: z.null(),
  totalCount: z.null(),
});

import { z } from 'zod';

export const typeOfCompanyEnum = z.enum([
  'Private Limited Company (Ltd)',
  'Public Limited Company (Plc)',
  'Partnership',
  'Sole Trader',
]);

export type TypeOfCompany = z.infer<typeof typeOfCompanyEnum>;

export const typeOfContractorEnum = z.enum(['Individual', 'Limited Liability Company', 'Sole Trader']);

export type TypeOfContractor = z.infer<typeof typeOfContractorEnum>;

import { z } from 'zod';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const setupIntentClientSecretSchema = z.object({
  secret: z.string(),
});

const getSetupIntentClientSecretErrors = z.enum([
  'UnknownError',
  'UserNotAPartOfTheOrganization',
  'RequiresBillingInformationToAddAPaymentMethod',
  'CouldNotCreateStripeCustomer',
  'CouldNotCreateStripeSetupIntentSecret',
]);

export type SetupIntentClientSecretError = z.infer<typeof getSetupIntentClientSecretErrors>;

export const getSetupIntentClientSecretPathSchema = z.object({
  billingInformationId: z.string().uuid(),
});

export const getSetupIntentClientSecretQuerySchema = z
  .object({
    successUrl: z.string().url().optional(),
    cancelUrl: z.string().url().optional(),
  })
  .optional();

export const getSetupIntentClientSecretSuccessSchema = apiResponseSuccessSchema.extend({
  data: setupIntentClientSecretSchema,
});

export const getSetupIntentClientSecretErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getSetupIntentClientSecretErrors,
});

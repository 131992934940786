import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';
import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';

import { paginatedApiResponseFailureSchema, paginatedApiResponseSuccessSchema } from '../base/apiResponse';
import { paginatedRequestQuerySchema } from '../base/paginatedQuery';

const addOnsSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  descriptionUrl: z.string().url(),
  currencyCode: currencyCodeEnum,
  canBeFreeUpToMinimumAmountOfAddons: z.boolean(),
  cost: z.number().min(0),
  shortDescription: z.string(),
});

const pricingSchema = z.object({
  currency: currencyCodeEnum,
  monthlyPremiumPrice: z.number().min(0),
});

const availableHealthPlansSchema = z.object({
  id: z.string().uuid(),
  providerName: z.string(),
  providerIconUri: z.string(),
  name: z.string(),
  shortDescription: z.string(),
  descriptionUrl: z.string().url(),
  minimumIncludedAddons: z.number().min(0),
  hospitalListUrl: z.string().url(),
  tableOfCoverUrl: z.string().url(),
  pricing: pricingSchema,
  addOns: z.array(addOnsSchema),
  ipidUrl: z.string().url(),
});

export const getHealthPlansErrors = z.enum(['UnknownError', 'ErrorRetrievingPricingInformation']);

export const getHealthPlansQueryParamsSchema = paginatedRequestQuerySchema.extend({
  country: countryCodeEnum,
});

export const getHealthPlansSuccessSchema = paginatedApiResponseSuccessSchema.extend({
  data: z.array(availableHealthPlansSchema),
});

export const getHealthPlansErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getHealthPlansErrors,
});

import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const supportedCountryBenefitSchema = z.object({
  country: countryCodeEnum,
  health: z.boolean(),
  pension: z.boolean(),
});

export type SupportedCountry = z.infer<typeof supportedCountryBenefitSchema>;

const supportedCountriesBenefitsErrors = z.enum(['UnknownError']);

export const getCountriesSupportedBenefitsQueryParamsSchema = z.object({
  userType: z.enum(['contractor', 'organization']),
});

export const getCountriesSupportedBenefitsSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(supportedCountryBenefitSchema),
});

export const getCountriesSupportedBenefitsErrorSchema = apiResponseFailureSchema.extend({
  errorCode: supportedCountriesBenefitsErrors,
});

import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const countrySchema = z.object({
  alpha2: z.string().min(2).max(2),
  alpha3: z.string().min(3).max(3),
  code: z.number(),
  name: z.string(),
  region: z.string(),
  regionCode: z.number(),
  subRegion: z.string(),
  subRegionCode: z.number(),
});

export type Country = z.infer<typeof countrySchema>;

const regionSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  countryCodeEnum: countryCodeEnum,
});

const getCountriesErrors = z.enum(['UnknownError']);

export const getCountriesSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(countrySchema),
});

export const getCountriesErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getCountriesErrors,
});

export const getCountryPathParamsSchema = z.object({
  alpha2: countryCodeEnum,
});

const getCountryErrors = z.enum(['UnknownError', 'invalid_alpha2']);

export const getCountrySuccessSchema = apiResponseSuccessSchema.extend({
  data: countrySchema,
});

export const getCountryErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getCountryErrors,
});

const getCountryRegionsErrors = z.enum(['UnknownError', 'InvalidCountryCode']);

export const getCountryRegionsSuccessSchema = apiResponseSuccessSchema.extend({
  data: regionSchema,
});

export const getCountryRegionsErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getCountryRegionsErrors,
});

export const getCountryRegionsPathParamsSchema = getCountryPathParamsSchema;

import { z } from 'zod';

import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';
import { healthProviderEnum } from '@yonderlabs-packages/schemas-health-providers';
import { pensionProviderEnum } from '@yonderlabs-packages/schemas-pension-providers';

import { paginatedApiResponseFailureSchema, paginatedApiResponseSuccessSchema } from '../base/apiResponse';
import { paginatedRequestQuerySchema } from '../base/paginatedQuery';

const coverTransactionState = z.enum(['Processing', 'Paid', 'Retrying']);

const healthTransactionAmountSchema = z.object({
  organizationGrossContribution: z.number().min(0),
  organizationNetContribution: z.number().min(0),
  peopleNetContribution: z.number().min(0),
  insurancePremiumTax: z.number().min(0),
  netPremium: z.number().min(0),
});

const individualHealthTransactionSchema = z.object({
  peopleId: z.string().uuid(),
  amount: healthTransactionAmountSchema,
});

export type IndividualHealthTransaction = z.infer<typeof individualHealthTransactionSchema>;

const healthTransactionSchema = z.object({
  transactionId: z.string().uuid(),
  state: coverTransactionState,
  providerEnum: healthProviderEnum,
  currencyCode: currencyCodeEnum,
  amount: healthTransactionAmountSchema,
  individualTransactions: z.array(individualHealthTransactionSchema),
});

export type HealthTransaction = z.infer<typeof healthTransactionSchema>;

const pensionTransactionAmountSchema = z.object({
  organizationContribution: z.number().min(0),
  personalContribution: z.number().min(0),
  totalContribution: z.number().min(0),
});

const individualPensionTransactionSchema = z.object({
  peopleId: z.string().uuid(),
  amount: pensionTransactionAmountSchema.extend({
    organizationContributionPercentage: z.number().min(0).max(100),
    personalContributionPercentage: z.number().min(0).max(100),
  }),
});

export type IndividualPensionTransaction = z.infer<typeof individualPensionTransactionSchema>;

const pensionTransactionSchema = z.object({
  transactionId: z.string().uuid(),
  state: coverTransactionState,
  providerEnum: pensionProviderEnum,
  currencyCode: currencyCodeEnum,
  amount: pensionTransactionAmountSchema,
  individualTransactions: z.array(individualPensionTransactionSchema),
});

export type PensionTransaction = z.infer<typeof pensionTransactionSchema>;

const payrollTransactionsSchema = z.object({
  billingDate: z.preprocess((arg) => {
    if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
  }, z.date()),
  payrollFor: z.string(),
  healthTransaction: z.array(healthTransactionSchema),
  pensionTransaction: z.array(pensionTransactionSchema),
});

export type PayrollTransaction = z.infer<typeof payrollTransactionsSchema>;

const getPayrollTransactionsFileErrors = z.enum([
  'UnknownError',
  'UnableToFetchResults',
  'FileTypeNotSupportedException',
]);

export const getPayrollTransactionsFilePathSchema = z.object({
  billingInformationId: z.string().uuid(),
  year: z.number().min(1900).max(2100),
  month: z.number().min(1).max(12),
});

export const getPayrollTransactionsFileSuccessSchema = z.string();

export const getPayrollTransactionsFileErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getPayrollTransactionsFileErrors,
});

const getHealthTransactionsFileErrors = z.enum([
  'UnknownError',
  'UnableToFetchResults',
  'FileTypeNotSupportedException',
]);

export const getHealthTransactionsFilePathSchema = z.object({
  billingInformationId: z.string().uuid(),
  year: z.number().min(1900).max(2100),
  month: z.number().min(1).max(12),
  fileType: z.enum(['Xlsx', 'Csv', 'Pdf', 'Zip']),
});

export const getHealthTransactionsFileSuccessSchema = z.string();

export const getHealthTransactionsFileErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getHealthTransactionsFileErrors,
});

const getPensionTransactionsFileErrors = z.enum([
  'UnknownError',
  'UnableToFetchResults',
  'FileTypeNotSupportedException',
]);

export const gePensionTransactionsFilePathSchema = z.object({
  billingInformationId: z.string().uuid(),
  year: z.number().min(1900).max(2100),
  month: z.number().min(1).max(12),
  fileType: z.enum(['Xlsx', 'Csv', 'Pdf', 'Zip']),
});

export const getPensionTransactionsFileSuccessSchema = z.string();

export const getPensionTransactionsFileErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getPensionTransactionsFileErrors,
});

export const getInvoicesErrors = z.enum(['UnknownError', 'OrganizationDoesNotExist']);

export const getInvoicesPathSchema = z.object({
  billingInformationId: z.string().uuid(),
});

export const getInvoicesQueryParamsSchema = paginatedRequestQuerySchema;

const invoiceSchema = z.object({
  invoiceId: z.string().uuid(),
  invoiceReference: z.string(),
  generatedOn: z.preprocess((arg) => {
    if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
  }, z.date()),
  currency: currencyCodeEnum,
  description: z.string(),
  totalAmount: z.number(),
});

export type Invoice = z.infer<typeof invoiceSchema>;

export const getInvoicesSuccessSchema = paginatedApiResponseSuccessSchema.extend({
  data: z.array(invoiceSchema),
});

export const getInvoicesErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getInvoicesErrors,
});

export const getInvoiceFileSuccessSchema = z.string();

export const getInvoiceFileErrorSchema = paginatedApiResponseFailureSchema.extend({
  errorCode: getInvoicesErrors,
});

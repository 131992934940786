import { z } from 'zod';

import { countryCodeEnum, incorporatedCountryCodeEnum } from '@yonderlabs-packages/schemas-country';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const paymentJuristictionEnum = z.enum(['UK', 'EEA']);

export type PaymentJuristiction = z.infer<typeof paymentJuristictionEnum>;

const paymentMethodInformationSchema = z.object({
  brand: z.string().nullable(),
  description: z.string().nullable(),
  expiry: z
    .string()
    .transform((value) => new Date(value))
    .nullable(),
  type: z.string().nullable(),
});

export type PaymentMethodInformation = z.infer<typeof paymentMethodInformationSchema>;

const billingEntityAddressSchema = z.object({
  firstLine: z.string(),
  secondLine: z.string(),
  state: z.string(),
  countryCode: incorporatedCountryCodeEnum,
  postalCode: z.string(),
});

const feePercentageSchema = z.object({
  flatFee: z.number().nullable(),
  percentageFee: z.number(),
  minimumFee: z.number().nullable(),
  maximumFee: z.number().nullable(),
});

const billingEntitySchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string().email(),
  taxId: z.string(),
  registrationNumber: z.string(),
  vatRate: z.number(),
  address: billingEntityAddressSchema,
  isNecessaryInformationComplete: z.boolean(),
  billingDay: z.number(),
  billingZone: paymentJuristictionEnum,
  feePercentage: feePercentageSchema,
  paymentMethod: paymentMethodInformationSchema.nullable(),
});

export type BillingEntity = z.infer<typeof billingEntitySchema>;

export const getBillingEntitiesSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(billingEntitySchema),
});

const getBillingEntitiesErrorsEnum = z.enum(['UnknownError', 'LongPollNoPaymentMethod', 'LongPollTryLater']);

export const getBillingEntitiesErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getBillingEntitiesErrorsEnum,
});

export const getBillingEntitiesByCountrySuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(billingEntitySchema),
});

export const getBillingEntitiesByCountryErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getBillingEntitiesErrorsEnum,
});

export const getBillingEntitiesByCountryPathSchema = z.object({
  countryCode: countryCodeEnum,
});

export const getBillingEntitiesByScopeTypeCountryPathSchema = z.object({
  benefitScope: z.enum(['group', 'personal']),
  benefitType: z.enum(['health', 'pension', 'life']),
  countryCode: countryCodeEnum,
});

const getAllBillingZonesErrorsEnum = z.enum(['UnknownError']);

const billingZoneSchema = z.object({
  paymentJurisdiction: paymentJuristictionEnum,
  countryCodes: z.array(countryCodeEnum),
});

const billingZonesSchema = z.object({
  billingZones: z.array(billingZoneSchema),
});

export const getAllBillingZonesErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getAllBillingZonesErrorsEnum,
});

export const getAllBillingZonesSuccessSchema = apiResponseSuccessSchema.extend({
  data: billingZonesSchema,
});

export const getCountryBillingZonePathSchema = z.object({
  countryCode: countryCodeEnum,
});

const getCountryBillingZoneErrorsEnum = z.enum(['UnknownError']);

export const getCountryBillingZoneErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getCountryBillingZoneErrorsEnum,
});

const countryBillingZoneSchema = z.object({
  zone: paymentJuristictionEnum,
});

export const getCountryBillingZoneSuccessSchema = apiResponseSuccessSchema.extend({
  data: countryBillingZoneSchema,
});

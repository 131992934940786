import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';
import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

const currencySchema = z.object({
  code: currencyCodeEnum,
  name: z.string(),
  minorUnit: z.number(),
});

export type Currency = z.infer<typeof currencySchema>;

const currenciesErrors = z.enum(['UnknownError']);

export const getCurrenciesSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(currencySchema),
});

export const getCurrenciesErrorSchema = apiResponseFailureSchema.extend({
  errorCode: currenciesErrors,
});

const currencyErrors = z.enum(['UnknownError', 'invalid_alpha2']);

export const getCurrencySuccessSchema = apiResponseSuccessSchema.extend({
  data: currencySchema,
});

export const getCurrencyErrorSchema = apiResponseFailureSchema.extend({
  errorCode: currencyErrors,
});

export const getCurrencyPathParamsSchema = z.object({
  alpha2: countryCodeEnum,
});

import { z } from 'zod';

import { apiResponseFailureSchema, apiResponseSuccessSchema } from '../base/apiResponse';

export const initialiseKomboRequestSchema = z.never();

export const initialiseKomboSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.object({
    link: z.string().nonempty(),
  }),
});

export const komboErrorEnum = z.enum([
  'UnknownError',
  'InvalidOrganization',
  'HrisAlreadyConnected',
  'ExternalDependencyError',
]);

export type KomboError = z.infer<typeof komboErrorEnum>;

export const initialiseKomboErrorSchema = apiResponseFailureSchema.extend({
  errorCode: komboErrorEnum,
});

export const activateKomboRequestSchema = z.object({
  activationToken: z.string(),
});

export const activateKomboSuccessSchema = initialiseKomboSuccessSchema.extend({
  data: z.never(),
});

export const activateKomboErrorSchema = initialiseKomboErrorSchema;

const hrisConnectionStatusEnum = z.enum(['Initializing', 'Active', 'Invalid']);

export type HrisConnectionStatus = z.infer<typeof hrisConnectionStatusEnum>;

const hrisEmployeeSchema = z.object({
  total: z.number().nonnegative(),
  incomplete: z.number().nonnegative(),
  conflicting: z.number().nonnegative(),
});

export const hrisConnectionSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  logoUri: z.string().url(),
  status: hrisConnectionStatusEnum,
  employees: hrisEmployeeSchema,
  lastSyncDate: z.date().nullable(),
});

export const getHRISConnectionsSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(hrisConnectionSchema),
});

export const komboConnectionErrorEnum = komboErrorEnum.exclude(['HrisAlreadyConnected']);

export type KomboConnectionError = z.infer<typeof komboConnectionErrorEnum>;

export const getHRISConnectionsErrorSchema = apiResponseFailureSchema.extend({
  errorCode: komboConnectionErrorEnum,
});
